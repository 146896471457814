import { type ButtonHTMLAttributes } from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

export const buttonStyles = cva(
  [
    "rounded-md",
    "font-semibold",
    "focus-visible:outline",
    "focus-visible:outline-2",
    "focus-visible:outline-offset-2",
    "whitespace-nowrap",
    "text-center",
  ],
  {
    variants: {
      intent: {
        primary_green: [
          "bg-brand-mint_green-950",
          "text-white",
          "border-transparent",
          "hover:bg-brand-mint_green-800",
          "focus-visible:outline-brand-mint_green-950",
        ],
        secondary_green: [
          "bg-brand-mint_green-50",
          "border",
          "border-brand-mint_green-200",
          "text-slate-800",
          "hover:text-slate-900",
          "hover:bg-brand-mint_green-100",
          "focus-visible:outline-brand-mint_green-900",
        ],
        outline_green: [
          "bg-white",
          "text-gray-800",
          "border",
          "border-green-800",
          "hover:bg-brand-mint_green-50",
          "hover:border-brand-mint_green-950",
          "focus-visible:outline-brand-mint_green-800",
        ],
        outline_gray: [
          "bg-white",
          "text-gray-800",
          "border",
          "border-brand-davys_gray-300",
          "hover:bg-gray-50",
          "hover:border-gray-800",
          "hover:text-gray-900",
          "focus-visible:outline-brand-davys_gray-800",
        ],
        outline_apricot: [
          "bg-white",
          "text-gray-800",
          "border",
          "border-brand-davys_gray-300",
          "hover:bg-brand-apricot-400",
          "hover:border-brand-apricot-400",
          "focus-visible:outline-brand-apricot-400",
        ],
        remove: [
          "bg-red-200",
          "text-rose-950",
          "border",
          "border-red-300",
          "hover:bg-red-300",
          "hover:border-red-400",
          "focus-visible:outline-red-400",
        ],
        faded: [
          "bg-gray-300",
          "text-gray-800",
          "bg-opacity-50",
          "hover:bg-opacity-70",
          "hover:text-gray-900",
          "focus-visible:outline-gray-800",
        ],
      },
      size: {
        small: ["text-sm", "py-1", "px-2"],
        medium: ["text-base", "py-2", "px-4"],
        full: ["text-base", "py-2", "w-full", "px-4", "inline-block"],
      },
    },
    // compoundVariants: [
    //   { intent: "primary_gray", size: "medium", class: "uppercase" },
    // ],
    defaultVariants: {
      intent: "primary_green",
      size: "medium",
    },
  }
);

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonStyles> {}

export const Button: React.FC<ButtonProps> = ({
  className,
  intent,
  size,
  ...props
}) => (
  <button
    className={twMerge(buttonStyles({ intent, size, className }))}
    {...props}
  />
);
